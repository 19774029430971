import {UpdatePriorities} from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import {Gender} from "@source/api/models/residentModel";

export const ROUTES = {
  LoginPage: '/login',
  AzureADLoginPage: '/sso/:organization',
  AgencyLoginNamePage: '/agencyLoginName',
  AgencyLoginTypeNumberPage: '/agencyLoginTypeNumber',
  SetPasswordPage: '/verify',
  AccountHasBeenActivatedPage: '/activated',
  AgencyAccountHasBeenActivatedPage: '/agencyActivated',
  HomePage: '/home',
  NotFoundPage: '/404',
  AccessDeniedPage: '/accessDenied',
  ChangePasswordPage: '/changePassword',
  ResetPasswordPage: '/reset-password',
  ForgotPasswordPage: '/ForgotPassword',
  TwoFactorLoginPage: '/twoFactorLogin',
  EmptyURL: '/',
  AdminSuperAdminPanel: '/superAdminPanel', 
};

export const HOME_ROUTES = {
  AdminPanel: `${ROUTES.HomePage}/adminPanel`,
  AdminPanelStaff: `${ROUTES.HomePage}/adminPanel/staff`,
  AdminPanelHouses: `${ROUTES.HomePage}/adminPanel/houses`,
  AdminPanelAgencyStaff: `${ROUTES.HomePage}/adminPanel/agencyStaff`,
  AdminPanelFamilyMembers: `${ROUTES.HomePage}/adminPanel/familyMembers`,
  NewHome: `${ROUTES.HomePage}/newHome`,
  NewAgencyAccount: `${ROUTES.HomePage}/newAgencyAccount`,
  AgencyAccountChangePassword: `${ROUTES.HomePage}/agencyAccountChangePassword`,
  AgencyAccountForgotPassword: `${ROUTES.HomePage}/agencyAccountForgotPassword`,
  AgencyAccountSetPassword: `${ROUTES.HomePage}/agencyAccountSetPassword`,
  Residents: `${ROUTES.HomePage}/residents`,
  ResidentsArchive: `${ROUTES.HomePage}/residentsArchive`,
  Help: `${ROUTES.HomePage}/help`,
  NewResident: `${ROUTES.HomePage}/residents/newResident`,
  EditResident: `${ROUTES.HomePage}/residents/newResident/:residentId`,
  AddResidentFamilyMembers: `${ROUTES.HomePage}/residents/:residentId/addResidentFamilyMembers`,
  EditResidentFamilyMembers: `${ROUTES.HomePage}/residents/:residentId/addResidentFamilyMembers/:familyMemberId`,
  Resident: `${ROUTES.HomePage}/residents/:residentId`,
  ResidentArchive: `${ROUTES.HomePage}/residentsArchive/:residentId`,
  History: `${ROUTES.HomePage}/history`,
  Chat: `${ROUTES.HomePage}/chat`,
  Announcements: `${ROUTES.HomePage}/announcements`,
  NewAnnouncement: `${ROUTES.HomePage}/announcements/new`,
  Settings: `${ROUTES.HomePage}/settings`,
  Update: `${ROUTES.HomePage}/residents/:residentId/update/:updateId`,
  UpdateDraft: `${ROUTES.HomePage}/residents/:residentId/update`,
  NewEmployee: `${ROUTES.HomePage}/adminPanel/newEmployee`,
  Information: `${ROUTES.HomePage}/information`,
  InformationExports: `${ROUTES.HomePage}/information/reports`,
  InformationStats: `${ROUTES.HomePage}/information/statistics`,
}

export const SUPER_ADMIN_PANEL_ROUTES = {
  Organisations: `${ROUTES.AdminSuperAdminPanel}/organisations`,
  Residents: `${ROUTES.AdminSuperAdminPanel}/residents`,
  FamilyMembers: `${ROUTES.AdminSuperAdminPanel}/familyMembers`,
  Employees: `${ROUTES.AdminSuperAdminPanel}/employees`,
  NursingHomes: `${ROUTES.AdminSuperAdminPanel}/nursingHomes`,
}

export enum LogoType {
  White,
  Blue,
  Short
}

export const PasswordValidationRules = {
  SIZE: '[0-9a-zA-Z!@#$%^&*]{6,}',
  LOWER_CHAR: '(?=.*[a-z])',
  UPPER_CHAR: '(?=.*[A-Z])',
  NUMBER: '(?=.*[0-9])',
  SPECIAL_CHAR: '(?=.*[!@#$%^&*])'
}

export const getHelperItems = (value: any) => {
  let rules = 0;
  (new RegExp(PasswordValidationRules.LOWER_CHAR, 'g')).test(value) && rules++;
  (new RegExp(PasswordValidationRules.UPPER_CHAR, 'g')).test(value) && rules++;
  (new RegExp(PasswordValidationRules.NUMBER, 'g')).test(value) && rules++;

  const helperItems = [
    {title: 'Minimum six characters', valid: (new RegExp(PasswordValidationRules.SIZE, 'g')).test(value)},
    {title: 'Use at least 2 of the following:', subItems: [
      {title: 'Lower-case characters (a-z)', valid: (new RegExp(PasswordValidationRules.LOWER_CHAR, 'g')).test(value)},
      {title: 'Uppercase characters (A-Z)', valid: (new RegExp(PasswordValidationRules.UPPER_CHAR, 'g')).test(value)},
      {title: 'Digits (0-9)', valid: (new RegExp(PasswordValidationRules.NUMBER, 'g')).test(value)},
    ], valid: (rules >= 2)},
    
  ]
  
  return helperItems;
}

export const Colors = {
  primary01: '#3956C7',
  hover01: '#1C2F8F',
  warning: '#E6313C',
  border: '#DDE2F2',
  borderHover: '#e7e9f3',
  borderActive: '#3956C7',

  notificationSuccess: '#0DAF45',
  notificationWarning: '#E9850E',
  notificationError: '#DB1818',
}

export function formatDate(d?: Date | null, approximately?: boolean) {
  if(!d) return ''
  
  const date = d.getDate();
  const month = d.toLocaleDateString('en-us', { month:"long"})
  const hours = d.getHours() < 9 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 9 ? `0${d.getMinutes()}` : d.getMinutes();
  const day = new Intl.DateTimeFormat('en-US', { weekday: 'long'}).format(d)
  return `${day} ${date} ${month} ${d.getFullYear()} at ${approximately ? 'approximately ' : ''}${hours}:${minutes}`
}

export function deepClone(item) {
  if (!item) { return item; }

  if(item.__isYupSchema__) {
    return item // yup object clone incorrectly
  }

  var types = [ Number, String, Boolean ], 
      result;

  types.forEach(function(type) {
      if (item instanceof type) {
          result = type( item );
      }
  });

  if (typeof result == "undefined") {
      if (Object.prototype.toString.call( item ) === "[object Array]") {
          result = [];
          item.forEach(function(child, index, array) { 
              result[index] = deepClone( child );
          });
      } else if (typeof item == "object") {
          if (item.nodeType && typeof item.cloneNode == "function") {
              result = item.cloneNode( true );    
          } else if (!item.prototype) {
              if (item instanceof Date) {
                  result = new Date(item);
              } else {
                  result = {};
                  for (var i in item) {
                      result[i] = deepClone( item[i] );
                  }
              }
          } else {
              if (false && item.constructor) {
                  result = new item.constructor();
              } else {
                  result = item;
              }
          }
      } else {
          result = item;
      }
  }

  return result;
}

export function getPriorityColors(priorityName?: UpdatePriorities | '') {
  const PriorityColors = {
    [UpdatePriorities.Low]: '#0DAF45',
    [UpdatePriorities.Medium]: '#E9850E',
    [UpdatePriorities.High]: '#DB1818',
    [UpdatePriorities.Social]: '#E48DE5'
  }

  return priorityName ? PriorityColors[priorityName] : '#000';
}

export function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export function stringToHslColor(str: string) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;

  return 'hsl('+h+', '+35+'%, '+60+'%)';
}

export function getUrlId(url: string, prevRouteName: string) {
  const paths = url.split('/');
  return paths[paths.findIndex((path) => path === prevRouteName) + 1]
}

export function getGenderLabel(gender?: string) {
    switch (gender) {
        case Gender.MALE: return 'Male';
        case Gender.FEMALE: return 'Female';
        case Gender.NONE: return 'Yet to be advised';
        default: return 'Yet to be advised';
    }
}

export const disableFieldsOrganizations = process.env.REACT_APP_DISABLE_PARTIAL_FIELDS_ORGANIZATIONS?.split(',') || []
export const CPRAvailableECaseOrganizations = ['656ee31d478f7f2f67e91b5f']